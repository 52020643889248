import styled from 'styled-components';
import {
  alignItems,
  flexDirection,
  flexWrap,
  justifyContent,
} from 'styled-system';

import { Box } from 'components/Box';

/**
 * @deprecated Use `styled.div` instead
 */
export const Flex = styled(Box)`
  display: flex;
  ${flexWrap} ${flexDirection} ${alignItems} ${justifyContent};
`;
